import React, { FC } from 'react';
import { MainModulePage } from 'common/components';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { ReactComponent as Document } from 'img/icons/document.svg';
import { ReactComponent as Box } from 'img/icons/box.svg';
import { ReactComponent as Boxes } from 'img/icons/boxes.svg';
import messages from 'modules/services/messages/messages';
import commonMessages from 'common/messages/messages';
import { IMainModulePageRouteItem } from 'common/components/MainModulePage/MainModulePage';
import { PoliciesPermissionSetItems as Permission } from 'common/constants/permissions';
import { ServiceRoute } from 'modules/services/enums/service.enums';

const ServicesModules: IMainModulePageRouteItem[] = [
  {
    id: 'servicesList',
    message: messages.services,
    link: ServiceRoute.SERVICES_LIST,
    iconComponent: Box,
    viewBox: '0 0 24 24',
    permissions: [Permission.SERVICE_VIEW],
  },
  {
    id: 'feesList',
    message: commonMessages.fees,
    link: ServiceRoute.FEES_LIST,
    iconComponent: ReceiptIcon,
    viewBox: '0 0 24 24',
    permissions: [Permission.FEE_VIEW],
  },
  {
    id: 'servicePackages',
    message: messages.servicePackages,
    link: ServiceRoute.SERVICES_PACKAGES,
    iconComponent: Boxes,
    viewBox: '0 0 28 24',
    permissions: [Permission.SERVICE_BUNDLE_VIEW],
  },
  {
    id: 'documentTemplates',
    message: messages.documentTemplates,
    link: ServiceRoute.CONTRACT_DOCUMENT_TEMPLATES,
    iconComponent: Document,
    viewBox: '0 0 24 24',
    permissions: [Permission.CONTRACT_VIEW, Permission.WAIVER_VIEW],
  },
];

const MainPage: FC = () => {
  return <MainModulePage routes={ServicesModules} pageTitle="Services" />;
};

export default MainPage;
