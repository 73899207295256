import React, { FC } from 'react';
import messages from 'common/messages/messages';
import invoiceMessages from 'common/components/InvoiceOperating/messages';
import { Alert, DialogComponent, NumberTextField } from 'common/components';
import { Controller, useForm } from 'react-hook-form';
import IntlService from 'services/intl/IntlService';
import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';
import { AlertTypes } from 'common/interfaces/alerts';
import { Box } from '@material-ui/core';

interface IAddDiscountModalProps {
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (values: { discount: number }) => void;
  salespersonDiscount: number;
  discountValue: number | null;
}

const AddDiscountModal: FC<IAddDiscountModalProps> = (props: IAddDiscountModalProps) => {
  const { onClose, onSubmit, isLoading, salespersonDiscount, discountValue } = props;

  const formMethods = useForm<{ discount: number }>({
    defaultValues: {
      discount: discountValue !== null ? discountValue : salespersonDiscount,
    },
    mode: 'onBlur',
  });

  const { handleSubmit, control } = formMethods;

  return (
    <DialogComponent
      size="xs"
      title={IntlService.formatMessage(messages.addDiscount)}
      cancelBtnTitle={IntlService.formatMessage(messages.cancelBtn)}
      submitBtnTitle={IntlService.formatMessage(messages.addDiscount)}
      isOpen
      onClose={onClose}
      disableFullScreenBehavior
      loading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
    >
      <form>
        <Box mb={2}>
          <Alert
            title={IntlService.formatMessage(invoiceMessages.maximumApplicableDiscount, {
              discountValue: salespersonDiscount.toFixed(4),
            })}
            severity={AlertTypes.Info}
          />
        </Box>
        <Controller
          name="discount"
          control={control}
          render={({ field }) => (
            <NumberTextField
              value={field.value}
              onChange={v => field.onChange(v || 0)}
              onBlur={field.onBlur}
              label={IntlService.formatMessage(invoiceMessages.discount)}
              variant="outlined"
              fullWidth
              numberFormatProps={{
                ...defaultNumberFormatProps,
                decimalScale: 4,
                maxLength: 15,
                isAllowed: ({ floatValue }) => {
                  return !floatValue || floatValue <= salespersonDiscount;
                },
              }}
            />
          )}
        />
      </form>
    </DialogComponent>
  );
};

export default AddDiscountModal;
