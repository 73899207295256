import React, { FC } from 'react';
import { Grid, SvgIconTypeMap, Typography } from '@material-ui/core';
import { RouteBackground } from 'components';
import MainPageMenuItem from '../MainPageMenuItem/MainPageMenuItem';
import { Message } from 'common/interfaces/common';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { PoliciesPermissionSetItems as Permission } from 'common/constants/permissions';
import useHasPermissions from 'common/hooks/useHasPermissions';

export interface IMainModulePageRouteItem {
  id: string;
  message: Message;
  link: string;
  iconComponent: OverridableComponent<SvgIconTypeMap> | any;
  viewBox?: string;
  permissions?: Permission[];
}

interface IMainModulePage {
  routes: IMainModulePageRouteItem[];
  pageTitle: string;
}

const MainModulePage: FC<IMainModulePage> = ({ routes, pageTitle }) => {
  const hasPermissions = useHasPermissions();

  return (
    <RouteBackground hasMobilePadding>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h2">
            {pageTitle}
          </Typography>
        </Grid>

        <Grid item xs={12} md={10}>
          <Grid container alignItems="flex-start" spacing={2}>
            {routes.map(({ id, message, link, iconComponent, viewBox, permissions }) => {
              if (permissions && !hasPermissions(permissions)) {
                return null;
              }

              return (
                <MainPageMenuItem
                  key={id}
                  title={message}
                  path={link}
                  icon={iconComponent}
                  viewBox={viewBox}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </RouteBackground>
  );
};

export default MainModulePage;
