import React from 'react';
import { Link } from 'react-router-dom';
import { createStyles, makeStyles, Link as MaterialLink, Typography } from '@material-ui/core';
import { formatPersonalCode } from 'common/utils';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      padding: '2px 5px 2px 0',
      '&:active': {
        color: 'red',
      },
      '& button': {
        textAlign: 'left',
        textTransform: 'none',
      },
    },
  }),
);

interface ITableProfileLinkProps {
  to: string;
  title: string;
  id: number | string;
}

const TableProfileLink = ({ to, title, id }: ITableProfileLinkProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Link className={classes.link} to={to}>
      <MaterialLink component="button" underline="none">
        <Typography variant="h5" color="primary">{`${formatPersonalCode(
          Number(id),
        )} - ${title}`}</Typography>
      </MaterialLink>
    </Link>
  );
};

export default React.memo(TableProfileLink);
