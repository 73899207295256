export enum ServiceRoute {
  SERVICES = '/',
  CONTRACT_DOCUMENT_TEMPLATES = 'document-templates/contract',
  WAIVER_DOCUMENT_TEMPLATES = 'document-templates/waiver',
  CONTRACT_DOCUMENT_TEMPLATES_NEW = 'document-templates/contract/new',
  WAIVER_DOCUMENT_TEMPLATES_NEW = 'document-templates/waiver/new',
  CONTRACT_DOCUMENT_TEMPLATES_EDIT = 'document-templates/contract/:id',
  WAIVER_DOCUMENT_TEMPLATES_EDIT = 'document-templates/waiver/:id',
  SERVICES_LIST = 'services-list',
  SERVICES_LIST_NEW = 'services-list/new',
  SERVICES_LIST_ID = 'services-list/:serviceId',
  SERVICES_PACKAGES = 'service-packages',
  SERVICES_PACKAGES_NEW = 'service-packages/new',
  SERVICES_PACKAGES_EDIT = 'service-packages/:packageId',
  FEES_LIST = 'fees',
  FEES_NEW = 'fees/new',
  FEES_EDIT = 'fees/:feeId',
}
