import React, { useMemo, useState } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import {
  Box,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  Typography,
  Grid,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import classNames from 'classnames';
import { Order, IHeadCell, IMultipleSelectAction } from 'common/interfaces/table';
import { Checkbox } from 'common/components';
import EmptyTableCell from '../EmptyTableCell/EmptyTableCell';
import { CustomTheme } from 'common/ui/interfaces';

const useStyles = makeStyles((theme: CustomTheme) => ({
  selectAllCell: {
    width: 18,
    padding: '12px 12px 12px 0',
  },
  tableHeadCell: {
    textTransform: 'uppercase',
    fontWeight: 400,
    [theme.breakpoints.up('lg')]: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
      backgroundColor: '#fff',
      borderBottom: 'none',
      whiteSpace: 'nowrap',
      '&:after': {
        content: '""',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        borderBottom: `1px solid ${theme.palette.borderColor?.main}`,
      },
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  tableHeadCellNoBorder: {
    '&:after': {
      display: 'none',
    },
  },
}));

const StyledEmptyTableCell = styled(TableCell)({
  padding: 0,
  height: 12,
  width: 12,
  minWidth: 12,
});

interface IProps {
  columns: IHeadCell[];
  selectedRows: string[];
  isAllSelected: boolean;
  isRadioButtonMode: boolean;
  multipleSelectActions?: IMultipleSelectAction[];
  activeSelect?: boolean;
  showHeaderSelect?: boolean;
  smartView?: boolean;
  numSelected: number;
  totalRows: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabledSelectionsTotal: number;
  onSortClick: (order: Order, orderBy: string) => void;
  hidePadding?: boolean;
  resetSelected?: () => void;
  isEmptyRows: boolean;
  excludedIds?: string[];
  hideSelectedNumber?: boolean;
}

const TableHeader = ({
  columns,
  activeSelect,
  showHeaderSelect = true,
  selectedRows,
  multipleSelectActions,
  smartView,
  numSelected,
  totalRows,
  onSelectAllClick,
  onSortClick,
  hidePadding,
  isAllSelected,
  excludedIds = [],
  resetSelected = () => {},
  disabledSelectionsTotal,
  isRadioButtonMode,
  hideSelectedNumber = false,
  isEmptyRows,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('name');

  const handleSortClick = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';

    setOrder(newOrder);
    setOrderBy(property);

    onSortClick(newOrder, property);
  };

  const renderMultipleSelectActions = () => {
    return multipleSelectActions?.map(({ id, tooltip, ButtonIcon, className, onClick }, index) => (
      <Box key={id} component="span" ml={index ? 0.5 : 0}>
        {/* TODO - PRM-3575 need types */}
        <Tooltip title={tooltip as any}>
          <IconButton
            size="small"
            color="primary"
            disableRipple
            disableFocusRipple
            onClick={() => {
              if (onClick) {
                onClick(selectedRows, { resetSelected, isAllSelected, excludedIds });
              }
            }}
          >
            <ButtonIcon style={{ fontSize: '1.25rem' }} className={className} />
          </IconButton>
        </Tooltip>
      </Box>
    ));
  };

  const EmptyCell = smartView ? (
    <StyledEmptyTableCell className={classes.tableHeadCell} />
  ) : (
    <EmptyTableCell className={classNames(classes.tableHeadCell, classes.tableHeadCellNoBorder)} />
  );

  const isShownHeaders = useMemo(() => (!smartView && numSelected === 0) || hideSelectedNumber, [
    smartView,
    numSelected,
    hideSelectedNumber,
  ]);

  const total = totalRows ? totalRows - disabledSelectionsTotal : 0;
  const hasSelected = numSelected > 0;
  const isIndeterminate = hasSelected && numSelected < total;
  const isChecked = hasSelected && ((total > 0 && numSelected === total) || isAllSelected);

  return (
    <TableHead>
      <TableRow>
        {!hidePadding && EmptyCell}
        {activeSelect && (
          <TableCell className={classNames(classes.tableHeadCell, classes.selectAllCell)}>
            {showHeaderSelect && !isRadioButtonMode ? (
              <Tooltip title="Select">
                <Box component="span">
                  <Checkbox
                    indeterminate={isIndeterminate}
                    checked={isChecked}
                    onChange={onSelectAllClick}
                  />
                </Box>
              </Tooltip>
            ) : (
              <Box sx={{ width: 20 }} />
            )}
          </TableCell>
        )}

        {(smartView || numSelected > 0) && !hideSelectedNumber && (
          <TableCell
            className={classes.tableHeadCell}
            colSpan={smartView ? 2 : columns.length}
            padding="none"
          >
            <Grid container justifyContent="space-between" alignItems="center">
              {numSelected > 0 && (
                <>
                  <Grid item>
                    <Box>{renderMultipleSelectActions()}</Box>
                  </Grid>

                  <Grid item>
                    <Typography
                      component="span"
                      color="primary"
                      variant="subtitle2"
                      style={{ fontSize: 12 }}
                    >
                      {numSelected} SELECTED
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </TableCell>
        )}

        {isShownHeaders &&
          columns.map(({ id, label, sort, align, padding, nowrap }: IHeadCell, index, arr) => {
            const isLast = arr.length - 1 === index;

            return (
              <TableCell
                key={id}
                sortDirection={orderBy === id ? order : false}
                align={isLast && isEmptyRows ? 'right' : align || 'left'}
                padding={padding || 'default'}
                className={classes.tableHeadCell}
              >
                {sort ? (
                  <TableSortLabel
                    active={orderBy === id}
                    direction={orderBy === id ? order : 'asc'}
                    onClick={() => handleSortClick(id)}
                    className={nowrap ? classes.nowrap : undefined}
                    style={{
                      marginLeft: align === 'center' ? '14px' : '',
                    }}
                  >
                    {label}
                  </TableSortLabel>
                ) : (
                  <TableSortLabel
                    active={false}
                    hideSortIcon
                    disabled
                    className={nowrap ? classes.nowrap : undefined}
                  >
                    {label}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          })}
        {!hidePadding && EmptyCell}
      </TableRow>
    </TableHead>
  );
};

TableHeader.defaultProps = {
  smartView: false,
  activeSelect: false,
  multipleSelectActions: [],
};

export default React.memo(TableHeader);
