import { defineMessages } from 'react-intl';

export default defineMessages({
  newInvoice: {
    id: 'app.components.InvoiceOperating.newInvoice',
    defaultMessage: 'New invoice',
  },
  accountCredit: {
    id: 'app.components.InvoiceOperating.accountCredit',
    defaultMessage: 'Account Credit',
  },
  total: {
    id: 'app.components.InvoiceOperating.total',
    defaultMessage: 'Total',
  },
  maxTotalInvoice: {
    id: 'app.components.InvoiceOperating.maxTotalInvoice',
    defaultMessage: ' Max Total Invoice',
  },
  tax: {
    id: 'app.components.InvoiceOperating.tax',
    defaultMessage: 'Tax',
  },
  payWith: {
    id: 'app.components.InvoiceOperating.payWith',
    defaultMessage: 'Pay with',
  },
  discount: {
    id: 'app.components.InvoiceOperating.discount',
    defaultMessage: 'Discount',
  },
  subTotal: {
    id: 'app.components.InvoiceOperating.subTotal',
    defaultMessage: 'Subtotal',
  },
  leftToPay: {
    id: 'app.components.InvoiceOperating.leftToPay',
    defaultMessage: 'Left to pay',
  },
  addPayMethod: {
    id: 'app.components.InvoiceOperating.addPayMethod',
    defaultMessage: 'Add Payment Method',
  },
  paidWithTitle: {
    id: 'app.components.InvoiceOperating.paidWithRewards',
    defaultMessage: 'Paid with {title} {additionalInfo}',
  },
  addItems: {
    id: 'app.components.InvoiceOperating.addItems',
    defaultMessage: 'Items',
  },
  undergroundPromotion: {
    id: 'app.components.InvoiceOperating.undergroundPromotion',
    defaultMessage: 'Underground promotion',
  },
  left: {
    id: 'app.components.InvoiceOperating.left',
    defaultMessage: 'Left',
  },
  amount: {
    id: 'app.components.InvoiceOperating.amount',
    defaultMessage: 'Amount',
  },
  scanBarcode: {
    id: 'app.components.InvoiceOperating.scanBarcode',
    defaultMessage: 'Scan Barcode',
  },
  codeAlreadyUsed: {
    id: 'app.components.InvoiceOperating.promotionCard.used',
    defaultMessage: 'Code already used',
  },
  codeBlocked: {
    id: 'app.components.InvoiceOperating.promotionCard.blocked',
    defaultMessage: 'Code blocked',
  },
  initialFee: {
    id: 'app.components.InvoiceOperating.initialFee',
    defaultMessage: 'Initial Fee',
  },
  comingSoon: {
    id: 'app.components.InvoiceOperating.comingSoon',
    defaultMessage: 'Coming Soon',
  },
  doNotHavePermissionsToSell: {
    id: 'app.components.InvoiceOperating.doNotHavePermissionsToSell',
    defaultMessage: 'You do not have rights to sell the package',
  },
  pastDueShortLabel: {
    id: 'app.components.InvoiceOperating.pastDueShortLabel',
    defaultMessage: 'PD',
  },
  pastDueWaivedShortLabel: {
    id: 'app.components.InvoiceOperating.pastDueWaivedShortLabel',
    defaultMessage: 'PD (Waived)',
  },
  billingScheduleShortLabel: {
    id: 'app.components.InvoiceOperating.billingScheduleShortLabel',
    defaultMessage: 'BS',
  },
  // payment wizard
  selectPaymentMethodStepTitle: {
    id: 'app.components.InvoiceOperating.paymentWizard.stepTitle.select',
    defaultMessage: 'select payment method',
  },
  cashPaymentStepTitle: {
    id: 'app.components.InvoiceOperating.paymentWizard.stepTitle.cash',
    defaultMessage: 'cash',
  },
  creditCardPaymentStepTitle: {
    id: 'app.components.InvoiceOperating.paymentWizard.stepTitle.card',
    defaultMessage: 'credit card',
  },
  creditCardITransactTokenizeTitle: {
    id:
      'app.components.InvoiceOperating.paymentWizard.creditCard.tokenize.creditCardITransactTokenizeTitle',
    defaultMessage: 'ITransact tokenize credit card',
  },
  creditCardITransactTokenizeThankYouTitle: {
    id:
      'app.components.InvoiceOperating.paymentWizard.creditCard.tokenize.creditCardITransactTokenizeThankYouTitle',
    defaultMessage:
      'Your request was submitted. Check your credit cards list shortly, if no mistake and issue occurred your credit card will be shown sonely.',
  },
  creditCardCommerceHubTokenizeTitle: {
    id:
      'app.components.InvoiceOperating.paymentWizard.creditCard.tokenize.creditCardCommerceHubTokenizeTitle',
    defaultMessage: 'CommerceHub tokenize credit card',
  },
  creditCardCommerceHubTokenizeBadFormData: {
    id:
      'app.components.InvoiceOperating.paymentWizard.creditCard.tokenize.creditCardCommerceHubTokenizeBadFormData',
    defaultMessage: 'Bad form data, check fields: {variable_1}',
  },
  creditCardCommerceHubTokenizeBadSubmitConfig: {
    id:
      'app.components.InvoiceOperating.paymentWizard.creditCard.tokenize.creditCardCommerceHubTokenizeBadSubmitConfig',
    defaultMessage: 'Bad configuration data: {variable_1}',
  },
  creditCardCommerceHubTokenizeUndefinedError: {
    id:
      'app.components.InvoiceOperating.paymentWizard.creditCard.tokenize.creditCardCommerceHubTokenizeUndefinedError',
    defaultMessage: 'Unexpected error happened',
  },
  creditCardCommerceHubTokenizeHttpError: {
    id:
      'app.components.InvoiceOperating.paymentWizard.creditCard.tokenize.creditCardCommerceHubTokenizeHttpError',
    defaultMessage: 'HttpError occurred: {variable_1} {variable_2}. {variable_3}',
  },
  otherTypePaymentStepTitle: {
    id: 'app.components.InvoiceOperating.paymentWizard.stepTitle.other',
    defaultMessage: 'Gift card, bonus points, etc.',
  },
  amountMoreThanLeft: {
    id: 'app.components.InvoiceOperating.paymentWizard.other.amountMoreThanLeft',
    defaultMessage: 'Amount should not be more than left on payment method',
  },
  amountMoreThanLeftToPay: {
    id: 'app.components.InvoiceOperating.paymentWizard.other.amountMoreThanLeftToPay',
    defaultMessage: 'Amount should not be more than left to pay',
  },
  amountMoreThanPaymentMethodPercentage: {
    id: 'app.components.InvoiceOperating.paymentWizard.other.amountMoreThanLeftToPay',
    defaultMessage:
      "Amount should be less than {percentage}% of invoice's subtotal that is {amount}",
  },
  payViaTerminal: {
    id: 'app.components.InvoiceOperating.paymentWizard.cc.payViaTerminal',
    defaultMessage: 'Pay via Payment Terminal',
  },
  paymentOptionTypeViaTerminal: {
    id: 'app.components.InvoiceOperating.paymentWizard.cc.paymentOptionTypeViaTerminal',
    defaultMessage: 'with Payment Terminal',
  },
  paymentOptionTypeViaStoreCard: {
    id: 'app.components.InvoiceOperating.paymentWizard.cc.payWithStoredCard',
    defaultMessage: 'with Stored Card',
  },
  paymentOptionTypeViaImmediateCreditCard: {
    id: 'app.components.InvoiceOperating.paymentWizard.cc.payWithImmediateCreditCard',
    defaultMessage: 'Pay with Immediate Credit Card',
  },
  paidOptionTypeViaImmediateCreditCard: {
    id: 'app.components.InvoiceOperating.paymentWizard.cc.paidWithImmediateCreditCard',
    defaultMessage: 'with Immediate Credit Card',
  },
  disabledCustomMethodTooltipTitle: {
    id:
      'app.components.InvoiceOperating.paymentWizard.stepTooltip.disabledCustomMethodTooltipTitle',
    defaultMessage: 'Payment via custom payment method allowed only for concrete club',
  },
  disableMethodForNotInSystem: {
    id: 'app.components.InvoiceOperating.paymentWizard.stepTooltip.disableMethodForNotInSystem',
    defaultMessage: 'Operation is not supported for Not in System',
  },
  disableMethodForNotEnoughFundsonBalance: {
    id:
      'app.components.InvoiceOperating.paymentWizard.stepTooltip.disableMethodForNotEnoughFundsonBalance',
    defaultMessage: 'Not enough funds on balance',
  },
  disabledMagneticStripeForITransactTooltip: {
    id:
      'app.components.InvoiceOperating.paymentWizard.stepTooltip.disabledMagneticStripeForITransactTooltip',
    defaultMessage: 'Disabled for iTransact',
  },
  maximumApplicableDiscount: {
    id: 'app.components.InvoiceOperating.maximumApplicableDiscount',
    defaultMessage: 'The maximum applicable discount for current employees is {discountValue}%.',
  },
});
